import { ActionEvent, Controller } from "@hotwired/stimulus";
import { ValueDefinitionMap } from "@hotwired/stimulus/dist/types/core/value_properties";

type MapaEtiquetas = Record<string, string>;

export default class extends Controller {
  static targets = ["ctaEntidad"];
  declare labelTarget: HTMLElement;

  static values = {
    mapaEtiquetas: Object,
  };

  declare mapaEtiquetasValue: MapaEtiquetas;

  updateLabel({ target }: ActionEvent) {
    const selectedValue = (target as HTMLSelectElement).value;
    const etiqueta = this.mapaEtiquetasValue[selectedValue];
    //asiganar etiqueta al label respectivo
    this.labelTarget.innerText = etiqueta;
  }
}
