import { Context, Controller } from "@hotwired/stimulus";

type setSelectionRangeParameters = Parameters<
  HTMLInputElement["setSelectionRange"]
>;

export interface InputSelection {
  start: setSelectionRangeParameters[0];
  end: setSelectionRangeParameters[1];
  direction: setSelectionRangeParameters[2];
}

export type SelectableElement = Element & {
  [Property in keyof Pick<
    HTMLInputElement,
    | "selectionStart"
    | "selectionEnd"
    | "selectionDirection"
    | "setSelectionRange"
  >]: HTMLInputElement[Property];
};

interface SelectionControllerInterface<Element extends SelectableElement>
  extends Controller<Element> {
  selection: InputSelection;
  makeSelection(): void;
  assignSelection(): void;
}

export class SelectionComposableController<Element extends SelectableElement>
  extends Controller<Element>
  implements SelectionControllerInterface<Element>
{
  declare selection: InputSelection;
  declare makeSelection: () => void;
  declare assignSelection: () => void;
}

export class SelectionController<
  Element extends SelectableElement
> extends SelectionComposableController<Element> {
  constructor(context: Context) {
    super(context);
    requestAnimationFrame(() => {
      const [observe, unobserve] = useSelection(this);
      Object.assign(this, { observe, unobserve });
    });
  }

  declare observe: () => void;
  declare unobserve: () => void;
}

export function useSelection(
  composableController: Controller<SelectableElement>
) {
  const controller =
    composableController as SelectionComposableController<SelectableElement>;
  const targetElement = controller.element;

  const makeSelection = () => {
    controller.selection = {
      start: targetElement.selectionStart,
      end: targetElement.selectionEnd,
      direction: targetElement.selectionDirection || undefined,
    };
  };

  const assignSelection = () => {
    targetElement.setSelectionRange(
      controller.selection.start,
      controller.selection.end,
      controller.selection.direction
    );
  };

  const events = ["select", "drop"];
  const selectionHandler = makeSelection.bind(controller);
  const observe = () => {
    events.forEach((event) => {
      targetElement.addEventListener(event, selectionHandler);
    });
  };
  const unobserve = () => {
    events.forEach((event) => {
      targetElement.removeEventListener(event, selectionHandler);
    });
  };

  const controllerConnect = controller.connect.bind(controller);
  const connect = () => {
    observe();
    controllerConnect();
  };

  const controllerDisconnect = controller.disconnect.bind(controller);
  const disconnect = () => {
    unobserve();
    controllerDisconnect();
  };

  observe();
  if (!controller.selection) {
    makeSelection();
  }

  Object.assign(controller, {
    connect,
    disconnect,
    makeSelection,
    assignSelection,
  });

  return [observe, unobserve] as const;
}

type SelectableControllerConstructor = new (
  ...args: any[]
) => Controller<SelectableElement>;

export function SelectionMixin<
  BaseType extends SelectableControllerConstructor
>(Superclass: BaseType) {
  return class SelectionController
    extends Superclass
    implements SelectionControllerInterface<SelectableElement>
  {
    constructor(...args: any[]) {
      super(...args);
      requestAnimationFrame(() => {
        const [observe, unobserve] = useSelection(this);
        Object.assign(this, { observe, unobserve });
      });
    }

    declare selection: InputSelection;
    declare makeSelection: () => void;
    declare assignSelection: () => void;
    declare observe: () => void;
    declare unobserve: () => void;
  };
}
