import { Controller } from "@hotwired/stimulus";
import { SelectionMixin } from "../mixins/use-selection";
import { StrictValidationMixin } from "../mixins/use-validation";
import { Validator } from "../mixins/use-validation/use-validation";
import { onlyDigits } from "../mixins/use-validation/validators";

export interface CedulaRequirements {
  length: {
    min: number;
    max: number;
  };
}

export interface CedulaRequirementsEvent extends CustomEvent {
  detail: CedulaRequirements;
}

export default class CedulaController extends StrictValidationMixin(
  SelectionMixin(Controller<HTMLInputElement>)
) {
  static values = {
    minLength: { type: Number, default: 0 },
    maxLength: { type: Number, default: 0 },
    readOnly: { type: Boolean, default: false },
    invalidDigitError: { type: String, default: "Only digits allowed" },
    isInvalidDigitAnnoying: { type: Boolean, default: false },
    invalidLengthError: { type: String, default: "Invalid length" },
    isInvalidLengthAnnoying: { type: Boolean, default: false },
  };

  // values
  declare minLengthValue: number;
  declare maxLengthValue: number;
  declare readOnlyValue: boolean;
  declare invalidDigitErrorValue: string;
  declare isInvalidDigitAnnoyingValue: boolean;
  declare invalidLengthErrorValue: string;
  declare isInvalidLengthAnnoyingValue: boolean;

  get isReadOnly() {
    return this.element.readOnly;
  }

  // ValidationMixin
  getValidators(): Validator[] {
    return [
      {
        ...onlyDigits,
        errorMessage: this.invalidDigitErrorValue,
        isAnnoying: this.isInvalidDigitAnnoyingValue,
      },
      {
        callback: this.validateLength,
        errorMessage: this.invalidLengthErrorValue,
        isAnnoying: this.isInvalidLengthAnnoyingValue,
      },
    ];
  }

  validateLength(value: string): boolean {
    return (
      value.length >= this.minLengthValue && value.length <= this.maxLengthValue
    );
  }

  // tipo-cedula change listener
  adjustRequirements({ detail }: CedulaRequirementsEvent) {
    if (this.isReadOnly) {
      return;
    }

    const { length } = detail;
    this.minLengthValue = length.min;
    this.maxLengthValue = length.max;

    this.element.minLength = this.minLengthValue;
    this.element.maxLength = this.maxLengthValue;

    this.element.value = this.validValue = "";
  }
}
