
import { Controller, ActionEvent } from "@hotwired/stimulus";

  
export default class extends Controller {
  static values = {ruta: String,isSuper: Boolean}

  static targets =
  [
    'txtUsuario',
    'txtCompania',
    'txtContador',
    'chkContador',
    'cmbMes',
    'dpFechaInicio',
    'dpFechaFin',
    'errorMessage',
    'btnBuscarUsuario',
    'btnBuscarCia',
    'btnGetContador'
  ]

  
  declare rutaValue: string;
  declare isSuperValue: boolean;
  declare txtUsuarioTarget: HTMLInputElement;
  declare txtCompaniaTarget: HTMLInputElement;
  declare txtContadorTarget : HTMLInputElement;
  declare cmbMesTarget: HTMLInputElement;
  declare dpFechaInicioTarget: HTMLInputElement;
  declare dpFechaFinTarget: HTMLInputElement;
  declare chkContadorTarget: HTMLInputElement;
  declare errorMessageTarget: HTMLSpanElement;
  declare btnBuscarCiaTarget: HTMLButtonElement;
  declare btnBuscarUsuario: HTMLButtonElement;
  declare btnGetContadorTarget: HTMLButtonElement;

  declare tableUsuarios:any;
  declare tableCias: any;
  declare tableContadores: any;



  buscarReporteHistorialByCont(){
    this.errorMessageTarget.innerText = "";
    let idUsuario : string = this.txtUsuarioTarget.value == '' ? "0": this.txtUsuarioTarget.value;
    let idCia: string = this.txtCompaniaTarget.value == '' ? "0": this.txtCompaniaTarget.value;
    let incluirContadorIsChecked = this.chkContadorTarget.checked?1:0;
    let mes = this.cmbMesTarget.value == '' ? "0": this.cmbMesTarget.value;
    let fechaInicio = this.dpFechaInicioTarget.value == '' ? "0": this.dpFechaInicioTarget.value;
    let fechaFin = this.dpFechaFinTarget.value == '' ? "0": this.dpFechaFinTarget.value;

    if(!this.isNumeric(idUsuario)){
      this.errorMessageTarget.innerText = "La cédula debe de ser numérica"
      return;
    }
    if(!this.isNumeric(idCia)){
      this.errorMessageTarget.innerText = "El id de compañia solo puede contener números"
      return;
    }
    if(this.dpFechaInicioTarget.value == '' && this.dpFechaFinTarget.value != '' || this.dpFechaInicioTarget.value != '' && this.dpFechaFinTarget.value == ''){
      this.errorMessageTarget.innerText = "Si selecciona alguna fecha, tiene que seleccionar la otra para completar el periodo";
      this.dpFechaFinTarget.value = '';
      this.dpFechaInicioTarget.value = '';
      return;
    }
    if(this.isSuperValue && this.txtContadorTarget.value == ''){
      this.errorMessageTarget.innerText = "Debe seleccionar un contador";
      return;
    }
  
    var url = this.rutaValue + "/HistorialesTrabajo/GetAllUsuariosByIdContador/"+idUsuario+"/"+idCia+"/" + incluirContadorIsChecked + "/" + mes + "/" + fechaInicio + "/" + fechaFin + "/" + this.txtContadorTarget.value;
window.open(url);
this.limpiarCampos();
}


 isNumeric(value: string): boolean {
  return /^\d+$/.test(value);
}


buscarUsuariosByIdCont(){
  $.ajax({
    url: this.rutaValue + "/UsuariosdeContadores/obtenerUsuariosByIdCont",
    type: "POST",
    dataType: "json",
    success: function (data) {

       this.tableUsuarios = $("#tblUsuarios").DataTable();
       this.tableUsuarios.clear().draw();

      //agregar filas a datatable

      data.forEach((subArr : any) => {
        this.tableUsuarios.row
          .add([
            subArr["cedula"],
            subArr["nombre"],
          ])
          .draw(false);
      });
      if (data.length != 0) {
        //$("#btnSelectCia").prop("disabled", false);
        $("#btnSelectUsuario").show();
      } else {
        //$("#btnSelectCia").prop("disabled", true);
        $("#btnSelectUsuario").hide();
      }

      $('#buscarUsuarios').modal("show");


    },
  });
}

buscarCompaniasByIdCont(){
  $.ajax({
    url: this.rutaValue + "/Companias/obtenerCiasCont",
    type: "POST",
    dataType: "json",
    success: function (data) {

       this.tableCias = $("#tblCia").DataTable();
       this.tableCias.clear().draw();

      //agregar filas a datatable

      data.forEach((subArr : any) => {
        this.tableCias.row
          .add([
            subArr["cod_cia"],
            subArr["cod_nom"],
          ])
          .draw(false);
      });
      if (data.length != 0) {
        //$("#btnSelectCia").prop("disabled", false);
        $("#btnSelectCia").show();
      } else {
        //$("#btnSelectCia").prop("disabled", true);
        $("#btnSelectCia").hide();
      }

      $('#buscarCompania').modal("show");


    },
  });
}


getContador(){
  $.ajax({
    url: this.rutaValue + "/Usuarios/getContadores",
    type: "POST",
    dataType: "json",
    success: function (data) {

       this.tableContadores = $("#tblContadores").DataTable();
       this.tableContadores.clear().draw();

      //agregar filas a datatable

      data.forEach((subArr : any) => {
        this.tableContadores.row
          .add([
            subArr["codcontador"],
            subArr["nombre"],
          ])
          .draw(false);
      });
      if (data.length != 0) {
        //$("#btnSelectCia").prop("disabled", false);
        $("#btnSelectContador").show();
      } else {
        //$("#btnSelectCia").prop("disabled", true);
        $("#btnSelectContador").hide();
      }

      $('#buscarContador').modal("show");


    },
  });
}



limpiarCampos(){
  this.errorMessageTarget.innerText = "";
  this.txtUsuarioTarget.value = '';
  this.txtCompaniaTarget.value = '';
  this.dpFechaFinTarget.value = '';
  this.dpFechaInicioTarget.value = '';
  this.cmbMesTarget.value = '';
  this.txtContadorTarget.value ='';

}

}


