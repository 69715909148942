import { ActionEvent, Controller } from "@hotwired/stimulus";
import { CedulaRequirements } from "./cedula_controller";

export const defaultTipoCedulaRequirements: CedulaRequirements = {
  length: { min: 0, max: 0 },
};

export const tiposCedulaRequirements: Record<string, CedulaRequirements> = {
  F: {
    length: { min: 0, max: 9 },
  },
  J: {
    length: { min: 0, max: 10 },
  },
  N: {
    length: { min: 0, max: 10 },
  },
  D: {
    length: { min: 0, max: 12 },
  },
  E: {
    length: { min: 0, max: 20 },
  },
};

export default class TipoCedulaController extends Controller<HTMLSelectElement> {
  static values = {
    value: { type: String, default: "" },
  };

  // values
  declare valueValue: string;

  get isReadOnly() {
    return this.element.hasAttribute("readonly");
  }

  reportChange(event: ActionEvent) {
    if (this.isReadOnly) {
      event.stopPropagation();
      this.element.value = this.valueValue;
      return;
    }

    const value = this.element.value;
    this.valueValue = value;
    const detail: CedulaRequirements =
      tiposCedulaRequirements[value] || defaultTipoCedulaRequirements;
    this.dispatch("change", { detail });
  }
}
