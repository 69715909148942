import { Controller, Context } from "@hotwired/stimulus";
import {
  SelectableElement,
  SelectionComposableController,
} from "../use-selection/use-selection";

function usesSelection(
  obj: Controller
): obj is SelectionComposableController<SelectableElement> {
  return "assignSelection" in obj && typeof obj.assignSelection === "function";
}

export type ValidateableElement = Element & {
  [Property in keyof Pick<
    HTMLInputElement,
    "value" | "setCustomValidity" | "checkValidity" | "reportValidity"
  >]: HTMLInputElement[Property];
};

export type ValidationCallback = (value: string) => boolean;

export interface Validator {
  callback: ValidationCallback;
  errorMessage: string;
  isAnnoying?: boolean;
}

abstract class AbstractValidationController<
  Element extends ValidateableElement
> extends Controller<Element> {
  validValue = "";
  declare validate: (event: Event) => void;
  abstract getValidators(): Validator[];
}

export class ValidationComposableController<
  Element extends ValidateableElement
> extends AbstractValidationController<Element> {
  getValidators(): Validator[] {
    return [];
  }
}

export class StrictValidationController<
  Element extends ValidateableElement
> extends ValidationComposableController<Element> {
  constructor(context: Context) {
    super(context);
    requestAnimationFrame(() => {
      useStrictValidation(this);
    });
  }
}

export const useStrictValidation = (
  composableController: Controller<ValidateableElement>
) => {
  const controller =
    composableController as ValidationComposableController<ValidateableElement>;
  const targetElement = controller.element;

  const controllerUsesSelection = usesSelection(controller);

  const validate = (event: Event): void => {
    targetElement.setCustomValidity("");

    const failedValidation = controller.getValidators().find((validator) => {
      return !validator.callback.apply(controller, [targetElement.value]);
    });
    if (failedValidation) {
      targetElement.value = controller.validValue;
      if (controllerUsesSelection) {
        controller.assignSelection();
      }
      targetElement.setCustomValidity(failedValidation.errorMessage);
      event.stopImmediatePropagation();
      if (failedValidation.isAnnoying) {
        targetElement.reportValidity();
      }

      return;
    }

    controller.validValue = targetElement.value;
    if (controllerUsesSelection) {
      controller.makeSelection();
    }
  };

  const inputHandler = validate.bind(controller);
  const observe = () => {
    targetElement.addEventListener("input", inputHandler);
  };

  const unobserve = () => {
    targetElement.removeEventListener("input", inputHandler);
  };

  const controllerConnect = controller.connect.bind(controller);
  const connect = () => {
    observe();
    controllerConnect();
  };

  const controllerDisconnect = controller.disconnect.bind(controller);
  const disconnect = () => {
    observe();
    controllerDisconnect();
  };

  connect();
  if (!controller.validValue) {
    controller.validValue = "";
  }

  Object.assign(controller, {
    connect,
    disconnect,
    validate,
  });

  return [observe, unobserve] as const;
};

type ValidatableControllerConstructor = new (
  ...args: any[]
) => Controller<ValidateableElement>;

export function StrictValidationMixin<
  BaseType extends ValidatableControllerConstructor
>(Superclass: BaseType) {
  return class ValidationController
    extends Superclass
    implements AbstractValidationController<ValidateableElement>
  {
    constructor(...args: any[]) {
      super(...args);
      requestAnimationFrame(() => {
        const [observe, unobserve] = useStrictValidation(this);
        Object.assign(this, { observe, unobserve });
      });
    }

    declare validate: (event: Event) => void;
    declare validValue: string;
    getValidators(): Validator[] {
      return [];
    }
  };
}
