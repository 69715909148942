import { ActionEvent, Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["element", "inputsContainer"];
  static classes = ["hidden", "show"];

  declare elementTargets: HTMLElement[];
  declare inputsContainerTarget: HTMLElement;
  declare hiddenClass: string;
  declare showClass: string;

  clearInputs(event: ActionEvent) {
    let inputs = this.inputsContainerTarget.querySelectorAll("input");
    inputs.forEach((input) => {
      input.value = "";
    });
  }

  displayElement(event: ActionEvent) {
    let selectValue = (event.target as HTMLSelectElement).value;
    this.elementTargets.forEach((element) => {
      if (element.dataset.categorieId == selectValue) {
        element.classList.remove(this.hiddenClass);
        element.classList.add(this.showClass);
      } else {
        element.classList.remove(this.showClass);
        element.classList.add(this.hiddenClass);
      }
    });

    let inputs = this.inputsContainerTarget.querySelectorAll("input");
    inputs.forEach((input) => {
      input.value = "";
    });
  }
}
