import { Controller } from "@hotwired/stimulus";
import { Validator } from "../../mixins/use-validation/use-validation";
import { onlyDigits } from "../../mixins/use-validation/validators";
import { SelectionMixin } from "../../mixins/use-selection";
import { StrictValidationMixin } from "../../mixins/use-validation";

export default class extends StrictValidationMixin(
  SelectionMixin(Controller<HTMLInputElement>)
) {
  static values = {
    invalidDigitError: { type: String, default: "Only digits allowed" },
    isInvalidDigitAnnoying: { type: Boolean, default: false },
  };

  // values
  declare invalidDigitErrorValue: string;
  declare isInvalidDigitAnnoyingValue: boolean;

  // ValidationMixin
  getValidators(): Validator[] {
    return [
      {
        ...onlyDigits,
        errorMessage: this.invalidDigitErrorValue,
        isAnnoying: this.isInvalidDigitAnnoyingValue,
      },
    ];
  }
}
