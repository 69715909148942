/// <reference types="webpack/module" />

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

interface ApplicationWindow extends Window {
  Stimulus: Application;
}

declare const window: ApplicationWindow;

window.Stimulus = Application.start();
if (process.env.NODE_ENV === "development") {
  window.Stimulus.debug = true;
}

const context = require.context(
  "./stimulus/src/controllers",
  true,
  /\.[jt]sx?$/
);
window.Stimulus.load(definitionsFromContext(context));
